import React from "react";
import {UserAvatar} from "../components/user/UserAvatar"
import { Layout } from 'antd';

function Header() {
    const { Header } = Layout;
    
    return (
        <>
            <div className={"header"}>
                <div className={"logo-home"} >WILD SEARCH</div>
            </div>
            <Header className='bg-white px-4'>
                <div className='flex'>
                    <div className='flex-1' >
                        <div className='float-right mr-6'>
                            <UserAvatar />
                        </div>
                    </div>
                </div>
            </Header>
        </>
    )
}

export default Header