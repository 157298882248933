import React from "react";

function ProjectIntroduction() {
    return (
      <>
        <div id="introduction" className={'project-introduction'}>
          <div style={{height: '30px'}}></div>
          <div className={'intro-title'}>
            Precision in Nature Discovery
          </div>
          <div className={'intro-content'}>
            Advanced AI technology for 95% accurate data. simplifying research across land, sea, and air, <br/>
            our secure cloud storage ensures access to precise information anytime, anywhere.
          </div>
          <iframe src="https://www.youtube.com/embed/uCYREa2gmoE?si=8MKcKWzfewsBwjif" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
        </div>
      </>
    )
}
  
export default ProjectIntroduction