import { message } from "antd";
import QueryString from "qs";
import { jumpToLoginPage } from "./browser-utils";
import { clearAllCookies, getTokenFromCookie, getUidFromCookie, saveTokenToCookie } from "./cookie";

const TOKEN_HEADER = "Authorization";
const REQUEST_ID_HEADER = "X-Request-Id";
const BASE_URL = process.env.REACT_APP_BASE_URL;

const powerFetch = async (...args) => {
    let [url, config] = args;
    try {
        var result = await fetch(BASE_URL + url, config);
        if (result.headers.get(TOKEN_HEADER)) {
            saveTokenToCookie(result.headers.get(TOKEN_HEADER))
        }
        if (result.status === 500) {
            const token = config.headers.Authorization;
            for (var i=0; i<5; i++) {
                if (token !== getTokenFromCookie()) {
                    config.headers.Authorization = getTokenFromCookie();
                    result = await fetch(BASE_URL + url, config);
                    break;
                }
                sleep(500);
                clearAllCookies();
            }
        }
        // can add mock here
        return processResult(result);
    } catch (error) {
        return handleError(url, error);
    }
}

function sleep(delay) {
    var start = (new Date()).getTime();
    while((new Date()).getTime() - start < delay) {
        continue;
    }
}

function buildCommonHeader() {
    const token = getTokenFromCookie();
    const headers = {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Credentials': 'true',
        [REQUEST_ID_HEADER]: createRequestId(),
    };
    if (token) {
        headers[TOKEN_HEADER] = token;
    }
    return headers;
};

function handleError(url, error) {
    console.error(`[PowerAdmin]fetch error, url=${url}, error=${error}`)
    if (error.message.indexOf('timeout') !== -1) {
        message.error('Network timeout');
    } else if (error.message.indexOf('Failed to fetch') !== -1) {
        message.error('Network connection error');
    } else {
        message.error('nknown error');
    }
    return Promise.reject(error);
};

async function processResult(result) {
    const contentType = result.headers.get('Content-Type');
    if (!contentType || !contentType.includes('application/json')) {
        return Promise.reject({ msg: "error response" });
    }

    const resp = await result.json();
    if (resp.code && resp.code === 10000) {
        return Promise.resolve(resp.data);
    } else if (resp.code === undefined) {
        console.error("no code from resp:", resp)
        if (resp.message === "User does not exist or login is not valid.") {
            jumpToLoginPage();
        } else {
            message.error("Data format error")
        }
        return Promise.reject({ rawResp: resp, msg: "error response" });
    } else if (resp.code === 10002) {
        if (resp.msg !== "The token is invalid.") {
            message.error(resp.msg);
        }
        return Promise.resolve(resp.data);
    } else if (resp.code === 10006) {
        // message.error(resp.msg);
        clearAllCookies();
        const currentPath = window.location.pathname;
        if (currentPath !== "/" && currentPath !== "/login") {
            jumpToLoginPage();
        }
        return Promise.reject({ rawResp: resp, msg: "error response" });
    } else {
        if (resp.msg) {
            message.error(resp.msg);
        } else {
            message.error("Unknown error");
        }
    }
    return Promise.reject({ rawResp: resp, msg: "error response" });
}

export const checkTokenValid = async (url) => {
    const data = {"Authorization": getTokenFromCookie()};
    const params = new URLSearchParams(data).toString();
    const fullUrl = `${url}?${params}`;
    return await powerFetch(fullUrl, {
        method: "GET",
        credentials: 'include',
        headers: buildCommonHeader(),
    });
}

export const getRequest = async (url, data) => {
    const params = new URLSearchParams(data || {}).toString();
    const fullUrl = `${url}?${params}`;
    return await powerFetch(fullUrl, {
        method: "GET",
        credentials: 'include',
        headers: buildCommonHeader(),
    });
}

export const postRequestWithParams = async (url, data) => {
    const params = new URLSearchParams(data).toString();
    const fullUrl = `${url}?${params}`;
    return await powerFetch(fullUrl, {
        method: "POST",
        credentials: 'include',
        headers: {
            ...buildCommonHeader(),
            "Content-Type": "application/json;charset=UTF-8",
        },
    });
}

export const postRequest = async (url, data) => {
    return await powerFetch(url, {
        method: "POST",
        credentials: 'include',
        headers: {
            ...buildCommonHeader(),
            "Content-Type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify(data),
    });
}


export const putRequest = async (url, data) => {
    return await powerFetch(url, {
        method: "PUT",
        credentials: 'include',
        headers: {
            ...buildCommonHeader(),
            "Content-Type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify(data),
    });
}

export const putRequestWithParams = async (url, data) => {
    const params = new URLSearchParams(data).toString();
    const fullUrl = `${url}?${params}`;
    return await powerFetch(fullUrl, {
        method: "PUT",
        credentials: 'include',
        headers: {
            ...buildCommonHeader(),
            "Content-Type": "application/json;charset=UTF-8",
        },
    });
}

export const deleteRequest = async (url, data) => {
    return await powerFetch(url, {
        method: "DELETE",
        credentials: 'include',
        headers: {
            ...buildCommonHeader(),
        },
        body: JSON.stringify(data),
    });
}


function createRequestId() {
    let uid = getUidFromCookie();
    if (!uid) {
        uid = '000000';
    }
    // return `${uid}_${crypto.randomUUID()}`;
    return `${uid}`;
}