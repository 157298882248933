import { Button, Image } from "antd";
import { useEffect, useState } from "react";
import { labellingApi } from "../api/labelling-api";

export default function Labelling() {
    const [welcome, setWelcome] = useState("");

    let inited = false
    useEffect(() => {
        if (!inited) { // 防止开发环境react重复mount导致连续两次调用接口
            labellingApi.home().then(rspData => {
                setWelcome(rspData);
            });
        }
        return (() => { // 在下次mount再次运行effect之前会运行这个清理函数
            inited = true
        });
    }, []) // 第二个参数是指只在mount之后运行，而不是每次render都运行
    return (
        <>
            <div className='bg-white min-h-screen '>
                <Image preview={false} className='w-16' src="http://127.0.0.1:8080/images/IMG_0523_H600HH05209839_084727_20220808.JPG"></Image>
                {/* <h1 className='underline'>{welcome}</h1> */}
                <Button type='primary' >Click Me</Button>
            </div>
        </>
    )
}