import Header from "./Header";
import Footer from "./Footer";
import Home from "./Home";
import { GlobalInfoProvider } from '../GlobalInfoContext';
import { Layout} from 'antd';

import "./style/style.css"
import "../plugins/bootstrap.min.css"
import "../plugins/bootstrap.min.css.map"

function App() {
  return (
    <>
      <GlobalInfoProvider>
        <>
          <Layout style={{ height: '100%', display: 'flex', flexFlow: 'column' }}>
            <Header></Header>
            <Home></Home>
            <Footer></Footer>
          </Layout>
        </>
      </GlobalInfoProvider>
    </>
  );
}

export default App;
