import React from "react";

import ProjectIntroduction from "./ProjectIntroduction"
import Cover from "./Cover";
import ImageIntroduction from "./ImageIntroduction"
import StepPage from "./StepPage";

function Home() {
    return (
      <>
        <div className={'home'}>
            <Cover></Cover>
            <ProjectIntroduction></ProjectIntroduction>
            <ImageIntroduction></ImageIntroduction>
            <StepPage></StepPage>
        </div>
      </>
    )
}
  
export default Home