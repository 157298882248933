import React from "react";
import { Link } from 'react-scroll';

function Cover() {
    return (
      <>
        <div className={"cover"}>
            <div className={'cover-img'}>
                <div style={{height: '30px'}}></div>
                <div className={'cover-description-title'}>
                    <span>Exploring Animal Worlds <br/>Intelligent Insights</span>
                </div>
                <div className={'cover-description-content'}>
                    <div>
                        Multimedia AI analysis: <br/>
                        Advanced Deep Learning for Animal Recognition in<br/>Images, Videos & Audio
                    </div>
                </div>
            </div>
            <div className={'learn-more'}>
                <span>
                    <Link
                        className={'learn-more-content'}
                        activeClass="active"
                        to="introduction"
                        spy={true}
                        smooth={true}
                        offset={0}
                        duration={500}
                    >Learn more about us</Link>
                </span>
                <span className={"glyphicon glyphicon-menu-down"} aria-hidden="true"></span>
            </div>
        </div>
      </>
    )
}
  
export default Cover