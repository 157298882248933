import { useState, useRef, useEffect } from 'react'
import { Button, Form, Input, Layout, DatePicker, Modal } from "antd"
import { Swiper } from 'antd-mobile'
import { loginApi } from "../api/login/login-api";
import { jumpToAccountDetailsPage } from "../lib/browser-utils";
import { useNavigate } from 'react-router-dom';
import { userApi } from '../api/user/user-api';
import Footer from "../homePage/Footer";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/material.css'
import moment from 'moment';

import "./style/style.css"

const BASE_URL = process.env.REACT_APP_BASE_URL;

export default function Login() {
    const [loginSuccess, setLoginSuccess] = useState(false);
    const [currentPage, setCurrentPage] = useState('login');
    const navigate = useNavigate();
    const [createAccount, setCreateAccount] = useState({});
    const [emailExist, setEmailExist] = useState(false);
    const [loadingNext, setLoadingNext] = useState(false);
    const [loadingSubmit, setLoadingSubmit] = useState(false);
    const [checkCaptchaRst, setCheckCaptchaRst] = useState(true);
    const [isPhoneCheckSuccess, setIsPhoneCheckSuccess] = useState(true);

    const goHomePage = () => {
        navigate("/");
    };

    useEffect(() => {
        userApi.checkToken()
            .then(userData => {
                if (userData && Object.keys(userData).length !== 0) {
                    jumpToAccountDetailsPage();
                }
            })
            .catch(error => {
                console.error("[PowerAdmin]GlobalInforProvider error:", error)
            });
    }, [])

    const loginRef = useRef(null)

    const [birth, setBirth] = useState(null);

    const onFinish = async (data) => {
        setLoginSuccess(true);
        const rst = await loginApi.login(data);
        if (rst) {
            jumpToAccountDetailsPage();
        } else {
            setLoginSuccess(false);
        }
    };

    const submitRegister = async () => {
        const registerInfo = await loginApi.createAccount(createAccount);
        if (registerInfo) {
            jumpToAccountDetailsPage();
        }
        setLoadingSubmit(false);
    }

    const canRegister = async (data) => {
        const isExist = await loginApi.checkCanRegister(data);
        return isExist;
    }

    const clickSignUp = () => {
        setCurrentPage('signup');
        loginRef.current?.swipeNext();
    };

    const goLogin = () => {
        setCurrentPage('login');
        setLoginSuccess(false);
        setEmailExist(false);
        setLoadingNext(false);
        setLoadingSubmit(false);
        loginRef.current?.swipeTo(0);
    };

    const getName = async (data) => {
        setLoadingNext(true);
        if (await canRegister({userEmail: data.registerEmail})) {
            setLoadingNext(false);
            createAccount.emailAddress = data.registerEmail;
            createAccount.firstName = data.firstName;
            createAccount.lastName = data.lastName;
            setCurrentPage('signup2');
            loginRef.current?.swipeNext();
        } else {
            setEmailExist(true);
        }
        setLoadingNext(false);
    };

    const getBasicInfo = async (data) => {
        createAccount.birthDate = data.birth;
        createAccount.phoneNumber = '+'+data.phoneNumber;
        setCurrentPage('signup3');
        loginRef.current?.swipeNext();
    };

    const getPassword = async (data) => {
        createAccount.pwd = data.createPassword;
    };

    const changeBirth = (date, dateString) => {
        setBirth(dateString);
    };

    const checkCaptcha = async (data) => {
        const rst = await loginApi.checkCaptcha({verifyCode:data.captcha});
        return rst;
    }

    return (
        <>  
            <Layout style={{ height: '100%', display: 'flex', flexFlow: 'column' }}>
                <div className={"header"}>
                    <div className={"logo"} onClick={() => goHomePage()}>WILD SEARCH</div>
                </div>
                <div className={"login-container"}>
                    <div className={'text-container'}>
                        <div className={'login-left-title'}>
                            <span>Exploring Animal Worlds <br/>Intelligent Insights</span>
                        </div>
                        <div className={'login-left-content'}>
                            <div>
                                Multimedia AI analysis: <br/>
                                Advanced Deep Learning for Animal Recognition in<br/>Images, Videos & Audio
                            </div>
                        </div>
                    </div>
                    <div className={'login-content'}>
                        <Swiper     
                            ref={loginRef}
                            indicator={() => null} 
                            allowTouchMove={false}
                            style={{height: '100%', width: '100%'}}>
                            <Swiper.Item key={currentPage}>
                                <div className={'login-page-container'}>
                                    <div className="login-title">Login</div>
                                    <div style={{height: '10%'}}></div>
                                    <Form onFinish={onFinish} style={{width: '80%'}}>
                                        <>
                                        <Form.Item name="userEmail" rules={[
                                            { required: true, message: "The email cannot be empty!" },
                                            () => ({
                                                validator(_, value) {
                                                    var reg = /^[0-9a-zA-Z_.-]+[@][0-9a-zA-Z_.-]+([.][a-zA-Z]+){1,2}$/;
                                                    if (reg.test(value)) {
                                                        return Promise.resolve();
                                                    }
                                                    return Promise.reject(new Error('Please enter a valid email address.'));
                                                }
                                            })
                                            ]}>
                                            <Input className={'login-input'} placeholder="Please enter your email." />
                                        </Form.Item>
                                        <div style={{height: '2.5%'}}></div>
                                        <Form.Item name="pwd" rules={[{ required: true, message: "The password cannot be empty!" }]}>
                                            <Input.Password className={'login-input'} placeholder="Please enter your password." />
                                        </Form.Item>
                                        <div style={{height: '10%'}}></div>
                                        <Form.Item name="button">
                                            <div style={{width: '100%', display: 'flex', justifyContent: 'space-between'}}>
                                                <div className={'login-signup-text'} onClick={clickSignUp}>Register</div>
                                                <Button className="login-button" type="primary" htmlType="submit" loading={loginSuccess} >Login</Button>
                                            </div>
                                        </Form.Item>
                                        </>
                                    </Form>
                                </div>
                                </Swiper.Item>

                            <Swiper.Item key={currentPage}>
                                <div className={'login-page-container'}>
                                    <div className="login-title">Create an Account</div>
                                    <div style={{height: '10%'}}></div>
                                    <Form onFinish={getName} style={{width: '80%'}}>
                                        <>
                                        <Form.Item name="registerEmail" rules={[
                                            { required: true, message: "The email address cannot be empty!" },
                                            () => ({
                                                validator(_, value) {
                                                    var reg = /^[0-9a-zA-Z_.-]+[@][0-9a-zA-Z_.-]+([.][a-zA-Z]+){1,2}$/;
                                                    if (reg.test(value)) {
                                                        return Promise.resolve();
                                                    }
                                                    return Promise.reject(new Error('Please enter a valid email address.'));
                                                }
                                            })
                                            ]}
                                            validateStatus={emailExist ? 'error' : ''}
                                            help={emailExist ? "The email address already exists!" : null}>
                                            <Input className={'login-input'} placeholder="Please enter your email address." />
                                        </Form.Item>
                                        <div style={{height: '2.5%'}}></div>
                                        <div style={{display: 'flex'}}>
                                            <Form.Item name="firstName" rules={[{ required: true, message: "The first name cannot be empty!" }]}>
                                                <Input className={'login-input'} placeholder="First Name" />
                                            </Form.Item>
                                            <div style={{width: '5%'}}></div>
                                            <Form.Item name="lastName" rules={[{ required: true, message: "The last name cannot be empty!" }]}>
                                                <Input className={'login-input'} placeholder="Last Name" />
                                            </Form.Item>
                                        </div>
                                        <div style={{height: '10%'}}></div>
                                        <Form.Item name="button">
                                            <div style={{width: '100%', display: 'flex', justifyContent: 'space-between'}}>
                                                <div className={'login-signup-text'} onClick={goLogin}>Back</div>
                                                <Button className="login-button" type="primary" htmlType="submit" loading={loadingNext} >Next</Button>
                                            </div>
                                        </Form.Item>
                                        </>
                                    </Form>
                                </div>
                            </Swiper.Item>

                            <Swiper.Item key={currentPage}>
                                <div className={'login-page-container'}>
                                    <div className="login-title">Basic Information</div>
                                    <div style={{height: '10%'}}></div>
                                    <Form onFinish={getBasicInfo} style={{width: '80%'}}>
                                        <>
                                        <Form.Item name="birth" 
                                            rules={[{ required: true, message: "The birth date cannot be empty!" }]}
                                            getValueFromEvent={(...[, dateString]) => 
                                                dateString
                                            }
                                            getValueProps={(value) => ({
                                                value: value ? moment(value) : undefined
                                            })}
                                            >
                                            <DatePicker placeholder="Please enter your birthday." className={'login-input'} format="YYYY-MM-DD" onChange={changeBirth} renderExtraFooter={() => 'extra footer'} />
                                        </Form.Item>
                                        <div style={{height: '2.5%'}}></div>
                                        <Form.Item name="phoneNumber" rules={[
                                                () => ({
                                                    validator(_, value) {
                                                        const numberRegex = /\d{4,}/;
                                                        if (value && numberRegex.test(value)) {
                                                            setIsPhoneCheckSuccess(true);
                                                            return Promise.resolve();
                                                        }
                                                        setIsPhoneCheckSuccess(false);
                                                        return Promise.reject(new Error('The phone number is invalid!'));
                                                    }
                                                })
                                            ]}>
                                        <PhoneInput
                                            country="au"
                                            containerClass={isPhoneCheckSuccess ? 'PhoneInput-account-normal' : 'PhoneInput-account-error'}
                                            inputClass={'PhoneInput-account-input'}
                                            specialLabel=""
                                            />
                                        </Form.Item>
                                        <div style={{height: '10%'}}></div>
                                        <Form.Item name="button">
                                            <div style={{width: '100%', display: 'flex', justifyContent: 'space-between'}}>
                                                <div className={'login-signup-text'} onClick={goLogin}>Back</div>
                                                <Button className="login-button" type="primary" htmlType="submit" >Next</Button>
                                            </div>
                                        </Form.Item>
                                        </>
                                    </Form>
                                </div>
                            </Swiper.Item>

                            <Swiper.Item key={currentPage}>
                                <div className={'login-page-container'}>
                                    <div className="login-title">Create a Strong Password</div>
                                    <div style={{height: '10%'}}></div>
                                    <Form 
                                        onFinish={async (data) => {
                                            setLoadingSubmit(true);
                                            const rst = await checkCaptcha(data);
                                            setCheckCaptchaRst(rst);
                                            if (rst) {
                                                getPassword(data);
                                                submitRegister();
                                            } else {
                                                setLoadingSubmit(false);
                                            }
                                        }} style={{width: '80%'}}>
                                        <>
                                        <Form.Item name="createPassword" rules={[{ required: true, message: "The password cannot be empty!" }]}>
                                            <Input.Password className={'login-input'} placeholder="Please enter your password." />
                                        </Form.Item>
                                        {/* <div style={{height: '2.5%'}}></div> */}
                                        <Form.Item name="confirmPassword" rules={[
                                            { required: true, message: "The password cannot be empty!" },
                                            ({getFieldValue}) => ({
                                                validator(_, value) {
                                                    if (!value || getFieldValue('createPassword') === value) {
                                                        return Promise.resolve();
                                                    }
                                                    return Promise.reject(new Error('The two passwords that you entered do not match.'));
                                                }
                                            })
                                            ]}>
                                            <Input.Password className={'login-input'} placeholder="Please confirm your password." />
                                        </Form.Item>
                                        <div className={'captchaInputDiv'}>
                                            <Form.Item name="captcha" 
                                                validateStatus={checkCaptchaRst ? 'success' : 'error'}
                                                help={checkCaptchaRst ? null : "The verification code is incorrect!"}
                                                rules={[
                                                { required: true, message: "The verification code cannot be empty!" },
                                                () => ({
                                                    validator(_, value) {
                                                        setCheckCaptchaRst(true);
                                                        return Promise.resolve();
                                                    }
                                                })
                                                ]}>
                                                    <Input className={'login-input'} style={{width: '85%'}} placeholder="Verification Code" />
                                            </Form.Item>
                                            <img 
                                                id='captchaImage'
                                                style={{height: '40px', cursor: 'pointer'}} 
                                                src={BASE_URL+"/public/user/vc.jpg"}
                                                onClick={() => {
                                                    const img = document.getElementById('captchaImage');
                                                    if (img) {
                                                        const src = img.getAttribute('src');
                                                        if (src) {
                                                            var time=new Date().getTime();
                                                            img.setAttribute('src', src+'?'+time);
                                                        }
                                                    }
                                                }}
                                            />
                                        </div>
                                        <div style={{height: '5%'}}></div>
                                        <Form.Item name="button">
                                            <div style={{width: '100%', display: 'flex', justifyContent: 'space-between'}}>
                                                <div className={'login-signup-text'} onClick={goLogin}>Back</div>
                                                <Button className="login-button" type="primary" htmlType="submit" loading={loadingSubmit} >Submit</Button>
                                            </div>
                                        </Form.Item>
                                        </>
                                    </Form>
                                </div>
                            </Swiper.Item>
                        </Swiper>
                    </div>
                </div>
                <Footer></Footer>
            </Layout>
        </>
    )
}