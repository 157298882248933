import React from "react";

function ImageIntroduction() {
    return (
        <>
            <div className={'image-introduction'}>
                <div className={'image-introduction-text'}>
                    <div className={'image-introduction-text-title'}>
                        Previous Partnership Projects
                    </div>
                    <div className={'image-introduction-text-content'}>
                        Exploring Past Projects
                    </div>
                </div>
                <div className={'introduction-image-container'}>
                    <div className={"GP-image-container"}>
                        <img className={"golden-shouldered-parrot-with-tree"} src={require("./img/golden-shouldered_parrot_with_tree.png")} alt=""/>
                        <label className={'golden-shouldered-parrot-with-tree-label'}>Golden Shouldered Parrot</label>
                    </div>
                    <div className={'golden-shouldered-parrots-container'}>
                        <img className={"golden-shouldered-parrot1"} src={require("./img/golden-shouldered_parrot1.png")} alt=""/>
                        <img className={"golden-shouldered-parrot2"} src={require("./img/golden-shouldered_parrot2.png")} alt=""/>
                    </div>
                    <img className={"McNemo-fish-with-hand"} src={require("./img/McNemo_fish_with_hand.png")} alt=""/>
                    <div className={'McNemo-image-container'}>
                        <img className={"McNemo-fish-with-coral"} src={require("./img/McNemo_fish_with_coral.png")} alt=""/>
                        <label className={'golden-shouldered-parrot-with-coral-label'}>McNemo Fish</label>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ImageIntroduction