import { postRequestWithParams, putRequestWithParams, getRequest } from "../../lib/request"

export const projectApi = {
    listManagerData: async (data) => {
        return await postRequestWithParams(`/project/queryList`, data);
    },

    changeUserStatus: async (data) => {
        return await putRequestWithParams(`/project/projectReview`, data);
    },

    listProjects: async (data) => {
        return await getRequest(`/project/queryProjectList`, data);
    },

    requestProject: async (data) => {
        return await postRequestWithParams(`/project/applyPermissions`, data);
    },
};
