import React, { useContext } from "react";
import { GlobalInfoContext } from "../../GlobalInfoContext";
import { Avatar, Dropdown } from "antd";
import { loginApi } from "../../api/login/login-api";
import { jumpToLoginPage, jumpToLabellingPage, jumpToAccountDetailsPage } from "../../lib/browser-utils";
import { DoubleRightOutlined } from '@ant-design/icons';
// import { hasCookie } from "../../lib/cookie";
// import Cookies from "js-cookie";

import "./style.css"

export function UserAvatar() {
    const globalInfo = useContext(GlobalInfoContext);
    const handleLogout = async () => {
        await loginApi.logout();
        jumpToLoginPage();
    };

    const jumpToAccountDetails = async () => {
        jumpToAccountDetailsPage();
    };

    const items = [
        // {
        //     key: 'account',
        //     label: <a onClick={jumpToLabellingPage}>Labelling</a>
        // },
        {
            key: 'labelling',
            label: <a onClick={jumpToAccountDetails}>Go to My Account</a>
        },
        {
            type: 'divider'
        },
        {
            key: 'logout',
            label: <a onClick={handleLogout} style={{color: 'red'}}>Logout</a>
        },
    ]

    const handleAvatarClick = () => {
        if (Object.keys(globalInfo.user.firstName).length === 0) {
            jumpToLoginPage();
        } else {
            jumpToAccountDetailsPage();
        }
      };

    return (
        <>  
            {Object.keys(globalInfo.user.firstName).length !== 0 ? (
                <Avatar onClick={handleAvatarClick} shape={"square"} size={100} style={{
                    backgroundColor: "transparent", 
                    height: "50px",
                    width: "250px"
                    }}>
                    <Dropdown placement="bottomRight" menu={{ items }} trigger="hover" className="cursor-pointer">
                        <div className={"avatar-text"}>
                            {"Hello, "+globalInfo.user.firstName}
                            <DoubleRightOutlined style={{marginLeft: "5px", transform: "rotate(90deg)"}}/>
                        </div>
                    </Dropdown>
                </Avatar>
            ) : (
                <Avatar onClick={handleAvatarClick} size={40} style={{
                    backgroundColor: "transparent", 
                    height: "50px", 
                    width: "250px"
                }}>
                    <div className={"avatar-text"}>
                        Login
                    </div>
                </Avatar>
            )}
        </>
    )
}
