import React from 'react'
import { useState, useContext, useEffect, useRef } from 'react'
import { ArrowLeftOutlined, ArrowRightOutlined, CheckOutlined, QuestionCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Layout, Button, ConfigProvider, List, Avatar, Divider, Skeleton, Select, Input, Modal, Image, Flex, InputNumber} from 'antd';
import en_GB from 'antd/locale/en_GB';
import { Outlet, useLocation } from 'react-router-dom';
import Login from '../../loginPage/Login';
import { GlobalInfoProvider, GlobalInfoContext } from '../../GlobalInfoContext';
import { userApi } from "../../api/user/user-api";
import { UserAvatar } from '../user/UserAvatar';
import { jumpToLoginPage } from "../../lib/browser-utils";
import { labellingApi } from "../../api/labelling-api";
import { useNavigate } from 'react-router-dom';

import "./style.css"
import { elements } from 'chart.js';

const BASE_URL = process.env.REACT_APP_BASE_URL;
const { Header, Content, Footer, Sider } = Layout;
const savedPhotoDict = {};

export default function PowerLayout() {
    const navigate = useNavigate();
    const goHomePage = () => {
        navigate("/");
    };

    const globalInfo = useContext(GlobalInfoContext);
    if (globalInfo.user.email == "") {
        jumpToLoginPage();
    }

    const [collapsed, setCollapsed] = useState(false);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [imageNum, setImageNum] = useState();
    const [gspActiveButton, setGspActiveButton] = useState(null);
    const [gspVisibleButton, setGspVisibleButton] = useState(null);
    const [visibleNum, setVisibleNum] = useState(null);
    const [bandedNum, setBandedNum] = useState(0);
    const [gspNumActiveButton, setGspNumActiveButton] = useState(null);
    const [comments, setComments] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [submit, setSubmit] = useState(false);
    const [submitTitle, setSubmitTitle] = useState(false);
    const [selectedPhoto, setSelectedPhoto] = useState(null);
    const [selectedPhotoIndex, setSelectedPhotoIndex] = useState(0);
    const [scrollableTarget, setScrollableTarget] = useState(null);
    const [saving, setSaving] = useState(false);
    const [isShowAgeExamples, setIsShowAgeExamples] = useState(false);
    const [isShowBandedExamples, setIsShowBandedExamples] = useState(false);
    const [ageExamplesSex, setAgeExamplesSex] = useState('m');

    const [isShowWarning, setIsShowWarning] = useState(false);
    const [isGspActiveButtonWarning, setIsGspActiveButtonWarning] = useState(false);
    const [isGspVisibleButtonWarning, setIsGspVisibleButtonWarning] = useState(false);
    const [isGspNumActiveButtonWarning, setIsGspNumActiveButtonWarning] = useState(false);
    const [firstJumpWarning, setFirstJumpWarning] = useState(null);

    const [buttonStates, setButtonStates] = useState([]);
    const [gspStates, setGspStates] = useState([]);
    const [warningList, setWarningList] = useState([]);

    const [bandedWarning, setBandedWarning] = useState('');
    const [showBandedWarning, setShowBandedWarning] = useState(false);

    let currentPhoto = null;

    const leftUpperColorOptions = [
        { value: ['#FFFFFF01', '#FFFFFF01'], label: 'None' },
        { value: ['#FFFFFF02', '#FFFFFF02'], label: 'Unsure' },
        { value: ['#FFFFFF03', '#FFFFFF03'], label: 'Not Visible' },
        { value: ['blue', 'blue'], label: 'Blue' },
        { value: ['green', 'green'], label: 'Green' },
        { value: ['black', 'black'], label: 'Black' },
        { value: ['orange', 'orange'], label: 'Orange' },
        { value: ['purple', 'purple'], label: 'Purple' },
        { value: ['red', 'red'], label: 'Red' },
        { value: ['white', 'white'], label: 'White' },
        { value: ['yellow', 'yellow'], label: 'Yellow' },
    ];

    const leftLowerColorOptions = [
        { value: ['#FFFFFF01', '#FFFFFF01'], label: 'None' },
        { value: ['#FFFFFF02', '#FFFFFF02'], label: 'Unsure' },
        { value: ['#FFFFFF03', '#FFFFFF03'], label: 'Not Visible' },
        { value: ['gold', 'gold'], label: 'Gold' },
        { value: ['blue', 'blue'], label: 'Blue' },
        { value: ['blue', '#c2c5cc'], label: 'Blue Metal' },
        { value: ['brown', 'brown'], label: 'Brown' },
        { value: ['green', 'green'], label: 'Green' },
        { value: ['green', '#c2c5cc'], label: 'Green Metal' },
        { value: ['lime', 'lime'], label: 'Lime' },
        { value: ['#c2c5cc', '#c2c5cc'], label: 'Metal' },
        { value: ['#c2c5cc', 'blue'], label: 'Metal Blue' },
        { value: ['#c2c5cc', 'green'], label: 'Metal Green' },
        { value: ['#c2c5cc', 'black'], label: 'Metal Black' },
        { value: ['#c2c5cc', 'purple'], label: 'Metal Purple' },
        { value: ['black', 'black'], label: 'Black' },
        { value: ['black', '#c2c5cc'], label: 'Black Metal' },
        { value: ['orange', 'orange'], label: 'Orange' },
        { value: ['purple', 'purple'], label: 'Purple' },
        { value: ['red', 'red'], label: 'Red' },
        { value: ['red', 'yellow'], label: 'Red Yellow' },
        { value: ['white', 'white'], label: 'White' },
        { value: ['yellow', 'yellow'], label: 'Yellow' },
    ];

    const rightUpperColorOptions = [
        { value: ['#FFFFFF01', '#FFFFFF01'], label: 'None' },
        { value: ['#FFFFFF02', '#FFFFFF02'], label: 'Unsure' },
        { value: ['#FFFFFF03', '#FFFFFF03'], label: 'Not Visible' },
        { value: ['blue', 'blue'], label: 'Blue' },
        { value: ['brown', 'brown'], label: 'Brown' },
        { value: ['green', 'green'], label: 'Green' },
        { value: ['black', 'black'], label: 'Black' },
        { value: ['orange', 'orange'], label: 'Orange' },
        { value: ['purple', 'purple'], label: 'Purple' },
        { value: ['red', 'red'], label: 'Red' },
        { value: ['white', 'white'], label: 'White' },
        { value: ['yellow', 'yellow'], label: 'Yellow' },
    ];

    const rightLowerColorOptions = [
        { value: ['#FFFFFF01', '#FFFFFF01'], label: 'None' },
        { value: ['#FFFFFF02', '#FFFFFF02'], label: 'Unsure' },
        { value: ['#FFFFFF03', '#FFFFFF03'], label: 'Not Visible' },
        { value: ['gold', 'gold'], label: 'Gold' },
        { value: ['blue', 'blue'], label: 'Blue' },
        { value: ['blue', '#c2c5cc'], label: 'Blue Metal' },
        { value: ['green', 'green'], label: 'Green' },
        { value: ['green', '#c2c5cc'], label: 'Green Metal' },
        { value: ['lime', 'lime'], label: 'Lime' },
        { value: ['#c2c5cc', '#c2c5cc'], label: 'Metal' },
        { value: ['#c2c5cc', 'green'], label: 'Metal Green' },
        { value: ['#c2c5cc', 'black'], label: 'Metal Black' },
        { value: ['#c2c5cc', 'purple'], label: 'Metal Purple' },
        { value: ['#c2c5cc', 'blue'], label: 'Metal Blue' },
        { value: ['black', 'black'], label: 'Black' },
        { value: ['black', '#c2c5cc'], label: 'Black Metal' },
        { value: ['orange', 'orange'], label: 'Orange' },
        { value: ['purple', 'purple'], label: 'Purple' },
        { value: ['purple', '#c2c5cc'], label: 'Purple Metal' },
        { value: ['red', 'red'], label: 'Red' },
        { value: ['#708090', '#708090'], label: 'Slate' },
        { value: ['white', 'white'], label: 'White' },
        { value: ['yellow', 'yellow'], label: 'Yellow' },
    ];

    const colorConstraints = [
        ['None', 'Gold', 'None', 'Metal'],
        ['None', 'Gold', 'None', 'Red'],
        ['None', 'Blue', 'None', 'Metal'],
        ['None', 'Blue Metal', 'None', 'Metal'],
        ['None', 'Blue Metal', 'None', 'Red'],
        ['None', 'Blue', 'None', 'Red'],
        ['None', 'Blue', 'None', 'Yellow'],
        ['Brown', 'Black', 'None', 'Metal'],
        ['Brown', 'Red', 'None', 'Metal'],
        ['Brown', 'White', 'None', 'Metal'],
        ['None', 'Green', 'None', 'Lime'],
        ['None', 'Green', 'None', 'Metal'],
        ['None', 'Green Metal', 'None', 'Metal'],
        ['None', 'Green', 'None', 'Black'],
        ['None', 'Green', 'None', 'Red'],
        ['None', 'Green', 'None', 'Yellow'],
        ['None', 'Lime', 'None', 'Metal'],
        ['None', 'None', 'None', 'Red'],
        ['None', 'Lime', 'None', 'Red'],
        ['None', 'Metal', 'None', 'Gold'],
        ['None', 'Metal', 'None', 'Blue'],
        ['None', 'Metal', 'Blue', 'Blue'],
        ['None', 'Metal', 'None', 'Blue Metal'],
        ['None', 'Metal', 'None', 'Green'],
        ['None', 'Metal', 'Green', 'Green'],
        ['None', 'Metal', 'None', 'Green Metal'],
        ['None', 'Metal Green', 'None', 'Metal'],
        ['None', 'Metal', 'Green', 'Black'],
        ['None', 'Metal', 'None', 'Metal'],
        ['None', 'Metal', 'None', 'Metal Blue'],
        ['None', 'Metal', 'None', 'Metal Green'],
        ['None', 'Metal', 'None', 'Metal Black'],
        ['None', 'Metal', 'None', 'Black'],
        ['None', 'Metal Black', 'None', 'Metal'],
        ['None', 'Metal', 'Black', 'Black'],
        ['None', 'Metal', 'Orange', 'Blue'],
        ['None', 'Metal', 'Orange', 'Orange'],
        ['None', 'Metal', 'None', 'Purple'],
        ['None', 'Metal Purple', 'None', 'Metal'],
        ['None', 'Metal', 'Red', 'Purple'],
        ['None', 'Metal', 'Red', 'Red'],
        ['None', 'Metal', 'None', 'White'],
        ['None', 'Metal', 'None', 'Yellow'],
        ['None', 'Metal', 'Yellow', 'Brown'],
        ['None', 'Metal', 'Yellow', 'Black'],
        ['None', 'Metal', 'Yellow', 'Orange'],
        ['None', 'Metal', 'Yellow', 'Red'],
        ['None', 'Metal', 'Yellow', 'White'],
        ['None', 'Black', 'None', 'Gold'],
        ['None', 'Black', 'None', 'Blue'],
        ['None', 'Black', 'None', 'Green'],
        ['None', 'Black', 'None', 'Lime'],
        ['None', 'Black', 'None', 'Metal'],
        ['Black', 'Black', 'None', 'Metal'],
        ['None', 'Black', 'None', 'Red'],
        ['None', 'Black', 'None', 'Red'],
        ['None', 'Black', 'None', 'White'],
        ['None', 'Black', 'None', 'Yellow'],
        ['Black', 'Yellow', 'None', 'Metal'],
        ['Orange', 'White', 'None', 'Metal'],
        ['Orange', 'Yellow', 'None', 'Metal'],
        ['None', 'Purple', 'None', 'Metal'],
        ['None', 'Purple Metal', 'None', 'Metal'],
        ['None', 'Purple Metal', 'None', 'Red'],
        ['None', 'Purple', 'None', 'Red'],
        ['None', 'Red', 'None', 'Gold'],
        ['None', 'Red', 'None', 'Blue'],
        ['None', 'Red', 'None', 'Blue Metal'],
        ['None', 'Red', 'None', 'Green'],
        ['None', 'Red', 'None', 'Metal'],
        ['None', 'Red', 'None', 'Metal Purple'],
        ['None', 'Red', 'None', 'Black'],
        ['None', 'Red', 'None', 'Purple'],
        ['None', 'Red', 'None', 'Red'],
        ['Red', 'Red', 'None', 'Metal'],
        ['None', 'Red', 'None', 'White'],
        ['None', 'Red', 'None', 'Yellow'],
        ['Red', 'Yellow', 'None', 'Metal'],
        ['None', 'Slate', 'None', 'Yellow'],
        ['White', 'Orange', 'None', 'Metal'],
        ['None', 'White', 'None', 'Red'],
        ['White', 'Red', 'None', 'Metal'],
        ['None', 'White', 'None', 'Yellow'],
        ['White', 'Yellow', 'None', 'Metal'],
        ['None', 'Yellow', 'None', 'Green'],
        ['None', 'Yellow', 'None', 'Metal'],
        ['None', 'Yellow', 'None', 'Black'],
        ['Yellow', 'Orange', 'None', 'Metal'],
        ['None', 'Yellow', 'None', 'Red'],
        ['Yellow', 'Red', 'None', 'Metal'],
        ['None', 'Yellow', 'None', 'White'],
        ['Yellow', 'White', 'None', 'Metal'],
        ['Yellow', 'White', 'None', 'Metal'],
        ['None', 'Yellow', 'None', 'Yellow'],
        ['Yellow', 'Yellow', 'None', 'Metal'],
        ['Orange', 'Red', 'None', 'Metal'],
        ['Black', 'Red', 'None', 'Metal'],
        ['Brown', 'Orange', 'None', 'Metal'],
        ['Red', 'Orange', 'None', 'Metal'],
        ['Orange', 'Orange', 'None', 'Metal'],
        ['Yellow', 'Black', 'None', 'Metal'],
        ['Black', 'Orange', 'None', 'Metal'],
        ['White', 'Black', 'None', 'Metal'],
        ['Orange', 'Black', 'None', 'Metal'],
    ];

    const scrollToTop = (anchorName) => {
        if (anchorName) {
            const anchorElement = document.getElementById(anchorName);
            if (anchorElement) {
                document.getElementById('label-sider').scrollTo({ top: 0, behavior: 'smooth' });
            }
        }
    }
    
    const scrollToId = (anchorName) => {
        if (anchorName) {
            const anchorElement = document.getElementById(anchorName);
            if (anchorElement) {
                anchorElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        }
    }

    useEffect(() => {
        setButtonStates((prevState) => {
            const newState = [...prevState];
            for (let i = prevState.length; i < bandedNum; i++) {
                newState.push({ 
                    sex: null, 
                    age: null, 
                    rightUpper: null,
                    rightLower: null,
                    leftUpper: null,
                    leftLower: null,
                });
            }
            return newState.slice(0, bandedNum);
        });

        setGspStates((prevState) => {
            const newState = [...prevState];
            for (let i = prevState.length; i < bandedNum; i++) {
                newState.push({ 
                    sex: null, 
                    age: null, 
                    rightUpper: null,
                    rightLower: null,
                    leftUpper: null,
                    leftLower: null,
                });
            }
            return newState.slice(0, bandedNum);
        });
    }, [bandedNum, buttonStates.length]);

    useEffect(() => {
        if (globalInfo.user.userRole) {
            loadMoreData();
        }
    }, [globalInfo.user.userRole]);

    useEffect(() => {
        if (globalInfo.user.userRole) {
            labellingApi.getImageNum({
                status: "unlabelled"
            }).then((body) => {
                setImageNum(body);
            })
        }
    }, [globalInfo.user.userRole])

    const toggleCollapsed = () => {
        setCollapsed(!collapsed);
    };

    const location = useLocation();
    if (location.pathname === '/login') {
        return <Login></Login>
    }

    const loadMoreData = () => {
        if (loading) {
          return;
        }
        labellingApi.getImageNum({
            status: "unlabelled"
        }).then((body) => {
            setImageNum(body);
        })
        setLoading(true);
        labellingApi.getPage(
            {
                pageNum: currentPage,
                pageSize: pageSize,
                status: "unlabelled"
            }
        )
        .then((body) => {
            setData([...data, ...body]);
            setCurrentPage(currentPage + 1);
            setLoading(false);

            if (!selectedPhoto && body.length > 0) {
                setSelectedPhoto(body[0]);
                setSelectedPhotoIndex(0);
                currentPhoto = body[0];
                if (!currentPhoto.fileName in savedPhotoDict) {
                    selectedPhoto.hasAnimal = 'N';
                }
            }
        })
        .catch(() => {
            setLoading(false);
        });
    };

    const handlePhotoClick = (photo) => {
        setSelectedPhoto(photo);
        if (!photo.fileName in savedPhotoDict) {
            selectedPhoto.hasAnimal = 'N';
        }
        currentPhoto = photo;
        clearButton(true);
        scrollToTop("label-sider");
    };

    const gspHandleClick = (buttonId, hasAnimal) => {
        if (hasAnimal === 'N') {
            var oldComments = comments;
            clearButton(true);
            setComments(oldComments);
            setSelectedPhoto(data[selectedPhotoIndex]);
        }
        selectedPhoto.hasAnimal = hasAnimal;
        setGspActiveButton(buttonId);
    };

    const gspVisibleClick = (buttonId, num) => {
        setGspVisibleButton(buttonId);
        setVisibleNum(num);
        selectedPhoto.visibleNum = num;
        if (num < bandedNum) {
            setBandedNum(0);
            gspNumHandleClick('button_num_0', 0);
        }
    };

    const setGspClick = (buttonId, hasAnimal) => {
        if (hasAnimal === 'N') {
            var oldComments = comments;
            clearButton(false);
            setComments(oldComments);
            setSelectedPhoto(data[selectedPhotoIndex]);
        }
        selectedPhoto.hasAnimal = hasAnimal;
        setGspActiveButton(buttonId);
    };

    const gspNumHandleClick = (buttonId, num) => {
        if (num === 0) {
            var oldComments = comments;
            var oldHasAnimal = selectedPhoto.hasAnimal;
            var oldVisibleNum = selectedPhoto.visibleNum;
            clearButtonAfterBandedChose0();
            setComments(oldComments);
            setSelectedPhoto(data[selectedPhotoIndex]);
            selectedPhoto.hasAnimal = oldHasAnimal;
            selectedPhoto.visibleNum = oldVisibleNum;
        }
        selectedPhoto.num = num
        setBandedNum(num);
        setGspNumActiveButton(buttonId);
    };

    const gspAgeHandleClick = (buttonId, age, index) => {
        setButtonStates((prevState) => {
            const newState = [...prevState];
            newState[index].age = buttonId;
            return newState;
        });

        setGspStates((prevState) => {
            const newState = [...prevState];
            newState[index].age = age;
            return newState;
        });
    };

    const gspSexHandleClick = (buttonId, sex, index) => {
        setButtonStates((prevState) => {
            const newState = [...prevState];
            newState[index].sex = buttonId;
            return newState;
        });

        setGspStates((prevState) => {
            const newState = [...prevState];
            newState[index].sex = sex;
            return newState;
        });
        if (sex !== 'unknown') {
            setAgeExamplesSex(sex);
        }
    };

    const clearButtonAfterBandedChose0 = () => {
        setButtonStates([]);
        setBandedNum(0);
        setGspStates([]);
    }

    const clearButton = (isShowSavedPhoto) => {
        setGspActiveButton(null);
        setGspVisibleButton(null);
        setVisibleNum(null);
        setGspNumActiveButton(null);
        clearButtonAfterBandedChose0();

        setComments('');
        if (isShowSavedPhoto) {
            showSavedPhoto();
        }
    }

    const setButton = (photo) => {
        setButtonStates(photo[1])
        setGspStates(photo[2])
        if (photo[0].hasAnimal == null) {
            setGspActiveButton(null);
        } else {
            if (photo[0].hasAnimal == 'Y') {
                setGspClick('button_in_y', 'Y');
            } else {
                setGspClick('button_in_n', 'N');
                setComments(photo[0].comments);
                return;
            }
        }

        if (photo[0].visibleNum == null) {
            setVisibleNum(null);
            setGspVisibleButton(null);
        } else {
            clearVisibleButton(photo[0].visibleNum);
        }
        
        setBandedNum(photo[0].num);
        setGspNumActiveButton('button_num_'+photo[0].num, photo[0].num);

        setComments(photo[0].comments);
    }

    const draftHandleClick = () => {
        setSubmit('Save draft in progress...');
        setIsModalOpen(true);
    };

    const checkButtonClicked = () => {
        var warning = null;
        var showWaring = false;
        if (gspActiveButton === null) {
            setIsGspActiveButtonWarning(true);
            if (warning === null) {
                warning = 'GSP-active';
            }
            showWaring = true;
        }
        if (gspActiveButton === 'button_in_n') {
            return showWaring;
        }
        if (gspVisibleButton === null && visibleNum === null) {
            setIsGspVisibleButtonWarning(true);
            if (warning === null) {
                warning = 'GSP-visible';
            }
            showWaring = true;
        }
        if (gspNumActiveButton === null) {
            setIsGspNumActiveButtonWarning(true);
            if (warning === null) {
                warning = 'GSP-number';
            }
            showWaring = true;
        }
        
        let tmpWarningList = []
        for (let i = 0; i < bandedNum; i++) {
            if (buttonStates[i].sex === null) {
                tmpWarningList.push('GSP-sex'+(i+1))
            }
            if (buttonStates[i].age === null) {
                tmpWarningList.push('GSP-age'+(i+1))
            }
        };

        if (tmpWarningList.length > 0) {
            warning = tmpWarningList[0]
            showWaring = true;
        }
        setWarningList(tmpWarningList);

        if (showWaring) {
            setIsShowWarning(true);
            setFirstJumpWarning(warning);
        }
        return showWaring;
    }

    const clearWarning = () => {
        setIsShowWarning(false);
        setIsGspActiveButtonWarning(false);
        setIsGspVisibleButtonWarning(false);
        setIsGspNumActiveButtonWarning(false);
        setWarningList([]);
        setFirstJumpWarning(null);
    }

    const jumpToWarning = () => {
        scrollToId(firstJumpWarning);
    }

    const isBandedColorCorrect = (colorContent) => {
        var colorList = [colorContent["rightUpper"], colorContent["rightLower"], 
                    colorContent["leftUpper"], colorContent["leftLower"]].map(item => item === null ? 'None' : item);
        const hasUnsureOrNotVisible = colorList.includes('Unsure') || colorList.includes('Not Visible');
        const isContained = colorConstraints.some((subList) => {
            if (hasUnsureOrNotVisible) {
                return subList.length === colorList.length && subList.every((value, index) => {
                    if (colorList[index] === 'Unsure' || colorList[index] === 'Not Visible') {
                        return true;
                    }
                    const lowerValue = value?.toLowerCase() ?? null;
                    const lowerColor = colorList[index]?.toLowerCase() ?? null;
                    return lowerValue === lowerColor;
                });
            } else {
                return subList.length === colorList.length && subList.every((value, index) => {
                    const lowerValue = value?.toLowerCase() ?? null;
                    const lowerColor = colorList[index]?.toLowerCase() ?? null;
                    return lowerValue === lowerColor;
                });
            };
        });
        return isContained;
    }

    const checkBandedColors = () => {
        setBandedWarning('');
        var warningMsg = '';
        for (let i = 1; i <= selectedPhoto.num; i++) {
            const colorContent = {
                leftLower: gspStates[i-1].leftLower,
                leftUpper: gspStates[i-1].leftUpper,
                rightLower: gspStates[i-1].rightLower,
                rightUpper: gspStates[i-1].rightUpper,
            };
            if (!isBandedColorCorrect(colorContent)) {
                if (warningMsg.length === 0) {
                    warningMsg = 'parrot '+String(i);
                } else {
                    warningMsg = warningMsg + ', parrot '+String(i);
                }
            }
        }
        setBandedWarning(warningMsg);
        if (warningMsg.length === 0) {
            return false;
        } else {
            return true;
        }
    }

    const saveHandleClick = () => {
        setSaving(true);
        clearWarning();
        if (checkButtonClicked()) {
            setSaving(false);
            return;
        }

        const checkRst = checkBandedColors();
        if (checkRst) {
            setShowBandedWarning(true);
        } else {
            selectedPhoto.comments = comments;
            const tmpStatus = selectedPhoto.markStatus;
            selectedPhoto.markStatus = 'L';
            labellingApi.save(generateLabelData())
            .then((body) => {
                if (body==true) {
                    // setSubmit('Submitting...');
                    // setIsModalOpen(true);
                    handleArrowClick('right');
                    clearButton(true);
                    setSaving(false);
                    return;
                } else {
                    selectedPhoto.markStatus = 'F';
                    setSubmitTitle('Error');
                    setSubmit('This image has been labelled by others, please label other images.');
                    setIsModalOpen(true);
                    handleArrowClick('right');
                    clearButton(true);
                    setSaving(false);
                }
            })
            .catch(() => {
                selectedPhoto.markStatus = tmpStatus;
                setSubmitTitle('Error');
                setSubmit('Save failed, please try again.');
                setIsModalOpen(true);
                setSaving(false);
            });
            savedPhotoDict[JSON.parse(JSON.stringify(selectedPhoto.fileName))] = JSON.parse(JSON.stringify([selectedPhoto, buttonStates, gspStates]));
        }
    };

    const generateLabelData = () => {
        const submitData = {
            photoId: selectedPhoto.photoId,
            userId: globalInfo.user.userId,
            markStatus: selectedPhoto.markStatus,
            hasAnimal: selectedPhoto.hasAnimal,
            validateFlag: "N",
            animalMarkResultList: [],
        }
        if (selectedPhoto.num === 0) {
            const labelData = {
                projectId: 1
            };
            const labelContent = {
                visibleNum: selectedPhoto[`visibleNum`] !== undefined ? selectedPhoto[`visibleNum`] : "None",
                comments: selectedPhoto[`comments`]
            };
            labelData.projectCharactersJson = JSON.stringify(labelContent);
            submitData.animalMarkResultList.push(labelData);
        }
        for (let i = 1; i <= selectedPhoto.num; i++) {
            const labelData = {
                projectId: 1
            };
            const labelContent = {
                visibleNum: selectedPhoto[`visibleNum`] !== undefined ? selectedPhoto[`visibleNum`] : "None",
                sex: gspStates[i-1].sex,
                age: gspStates[i-1].age,
                banded: 'Y',
                leftLower: gspStates[i-1].leftLower,
                leftUpper: gspStates[i-1].leftUpper,
                rightLower: gspStates[i-1].rightLower,
                rightUpper: gspStates[i-1].rightUpper,
                comments: selectedPhoto[`comments`]
            };
            labelData.projectCharactersJson = JSON.stringify(labelContent);
            submitData.animalMarkResultList.push(labelData);
        }

        // Try to fix hasAnimal is null.
        // if (typeof submitData.hasAnimal === 'undefined' || submitData.hasAnimal === null) {
        //     if (submitData.animalMarkResultList.length > 0) {
        //         var labelContent0 = JSON.parse(submitData.animalMarkResultList[0].projectCharactersJson)
        //         if (typeof labelContent0.visibleNum === 'undefined' || 
        //             labelContent0.visibleNum === "None" || 
        //             labelContent0.visibleNum === null) {
        //                 submitData.hasAnimal = "N"
        //         } else {
        //             submitData.hasAnimal = "Y"
        //         }
        //     } else {
        //         submitData.hasAnimal = "N"
        //     }
        // }
        return submitData;
    }

    const clearVisibleButton = (value) => {
        if (value > 3) {
            setGspVisibleButton(null);
        } else {
            setGspVisibleButton('button_num_'+value);
        }
        setVisibleNum(value);
        selectedPhoto.visibleNum = value;
        if (value < bandedNum) {
            setBandedNum(0);
            gspNumHandleClick('button_num_0', 0);
        }
    }

    const clearBandedButton = (value) => {
        if (value > visibleNum) {
            value = visibleNum
        }
        gspNumHandleClick('button_num_'+value, value);
        setBandedNum(value);
    }

    const handleRightUpperChange = (value, index) => {
        setButtonStates((prevState) => {
            const newState = [...prevState];
            newState[index].rightUpper = value.value;
            return newState;
        });

        setGspStates((prevState) => {
            const newState = [...prevState];
            newState[index].rightUpper = value.label[1];
            return newState;
        });
    };

    const handleRightLowerChange = (value, index) => {
        setButtonStates((prevState) => {
            const newState = [...prevState];
            newState[index].rightLower = value.value;
            return newState;
        });

        setGspStates((prevState) => {
            const newState = [...prevState];
            newState[index].rightLower = value.label[1];
            return newState;
        });
    };

    const handleLeftUpperChange = (value, index) => {
        setButtonStates((prevState) => {
            const newState = [...prevState];
            newState[index].leftUpper = value.value;
            return newState;
        });

        setGspStates((prevState) => {
            const newState = [...prevState];
            newState[index].leftUpper = value.label[1];
            return newState;
        });
    };

    const handleLeftLowerChange = (value, index) => {
        setButtonStates((prevState) => {
            const newState = [...prevState];
            newState[index].leftLower = value.value;
            return newState;
        });

        setGspStates((prevState) => {
            const newState = [...prevState];
            newState[index].leftLower = value.label[1];
            return newState;
        });
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleArrowClick = (direction) => {
        if (direction === 'left' && selectedPhotoIndex > 0) {
            setSelectedPhoto(data[selectedPhotoIndex - 1]);
            setSelectedPhotoIndex(selectedPhotoIndex - 1);
            currentPhoto = data[selectedPhotoIndex - 1];
            scrollableTarget.scrollTop = (selectedPhotoIndex-1)*213;
            if (!currentPhoto.fileName in savedPhotoDict) {
                selectedPhoto.hasAnimal = 'N';
            }
            clearButton(true);
        } else if (direction === 'right' && selectedPhotoIndex < data.length - 1) {
            scrollableTarget.scrollTop = selectedPhotoIndex*213;
            setSelectedPhoto(data[selectedPhotoIndex + 1]);
            setSelectedPhotoIndex(selectedPhotoIndex + 1);
            currentPhoto = data[selectedPhotoIndex + 1];
            if (!currentPhoto.fileName in savedPhotoDict) {
                selectedPhoto.hasAnimal = 'N';
            }
            clearButton(true);
        }
        scrollToTop("label-sider");
    };

    const showSavedPhoto = () => {
        if (currentPhoto != null && currentPhoto.fileName in savedPhotoDict) {
            setButton(savedPhotoDict[currentPhoto.fileName]);
        }
        setSelectedPhoto(currentPhoto);
        clearWarning();
    }

    const showAgeExamples = () => {
        setIsShowAgeExamples(true);
    }

    const showBandedExamples = () => {
        setIsShowBandedExamples(true);
    }

    const closeShowAgeExamples = () => {
        setIsShowAgeExamples(false);
    }

    const closeShowBandedExamples = () => {
        setIsShowBandedExamples(false);
    }

    const closeBandedWaring_yes = () => {
        setShowBandedWarning(false);

        selectedPhoto.comments = comments;
        const tmpStatus = selectedPhoto.markStatus;
        selectedPhoto.markStatus = 'L';
        labellingApi.save(generateLabelData())
        .then((body) => {
            if (body==true) {
                // setSubmit('Submitting...');
                // setIsModalOpen(true);
                handleArrowClick('right');
                clearButton(true);
                setSaving(false);
                return;
            } else {
                selectedPhoto.markStatus = 'F';
                setSubmitTitle('Error');
                setSubmit('This image has been labelled by others, please label other images.');
                setIsModalOpen(true);
                handleArrowClick('right');
                clearButton(true);
                setSaving(false);
            }
        })
        .catch(() => {
            selectedPhoto.markStatus = tmpStatus;
            setSubmitTitle('Error');
            setSubmit('Save failed, please try again.');
            setIsModalOpen(true);
            setSaving(false);
        });
        savedPhotoDict[JSON.parse(JSON.stringify(selectedPhoto.fileName))] = JSON.parse(JSON.stringify([selectedPhoto, buttonStates, gspStates]));
    }

    const closeBandedWaring_no = () => {
        setShowBandedWarning(false);
        setSaving(false);
    }

    const setExamplesAge_f = () => {
        setAgeExamplesSex('f');

    }

    const setExamplesAge_m = () => {
        setAgeExamplesSex('m');
    }

    const { Option } = Select;
    const { TextArea } = Input;

    const fle_example1 = `/img/age_examples/${ageExamplesSex}/${ageExamplesSex}_fle/1.JPG`
    const fle_example2 = `/img/age_examples/${ageExamplesSex}/${ageExamplesSex}_fle/2.JPG`
    const fle_example3 = `/img/age_examples/${ageExamplesSex}/${ageExamplesSex}_fle/3.JPG`

    const juv_example1 = `/img/age_examples/${ageExamplesSex}/${ageExamplesSex}_juv/1.JPG`
    const juv_example2 = `/img/age_examples/${ageExamplesSex}/${ageExamplesSex}_juv/2.JPG`
    const juv_example3 = `/img/age_examples/${ageExamplesSex}/${ageExamplesSex}_juv/3.JPG`

    const adu_example1 = `/img/age_examples/${ageExamplesSex}/${ageExamplesSex}_adu/1.JPG`
    const adu_example2 = `/img/age_examples/${ageExamplesSex}/${ageExamplesSex}_adu/2.JPG`
    const adu_example3 = `/img/age_examples/${ageExamplesSex}/${ageExamplesSex}_adu/3.JPG`

    const ageExamplesPage = () => {
        return (
            <>
                <Flex className='age_examples' vertical='vertical' style={{marginTop: '20px'}}>
                    <Flex >
                        <div style={{justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                            <p>Fle</p>
                        </div>
                        <Image className='age_examples_img' src={fle_example1} style={{width: '120px'}}/>
                        <Image className='age_examples_img' src={fle_example2} style={{width: '120px'}}/>
                        <Image className='age_examples_img' src={fle_example3} style={{width: '120px'}}/>
                    </Flex>
                    <Divider style={{marginTop: '10px', marginBottom: '10px'}}/>
                    <Flex >
                        <div style={{justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                            <p>Juv</p>
                        </div>
                        <Image className='age_examples_img' src={juv_example1} style={{width: '120px'}}/>
                        <Image className='age_examples_img' src={juv_example2} style={{width: '120px'}}/>
                        <Image className='age_examples_img' src={juv_example3} style={{width: '120px'}}/>
                    </Flex>
                    <Divider style={{marginTop: '10px', marginBottom: '10px'}}/>
                    <Flex >
                        <div style={{justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                            <p>Adu</p>
                        </div>
                        <Image className='age_examples_img' src={adu_example1} style={{width: '120px'}}/>
                        <Image className='age_examples_img' src={adu_example2} style={{width: '120px'}}/>
                        <Image className='age_examples_img' src={adu_example3} style={{width: '120px'}}/>
                    </Flex>
                </Flex>  
            </>
        )
    }

    const approximateAgeContent = (num, handleClick, index) => {
        const ageId = `GSP-age${num}`
        let warning;
        if (warningList.includes(ageId)) {
            warning = true;
        } else {
            warning = false;
        }
        if (buttonStates.length === bandedNum) {
            return (
            <>
                <Divider style={{marginTop: '10px', marginBottom: '10px'}}/>
                <div id={ageId} style={{
                    paddingTop: '10px',
                    paddingBottom: '10px',
                    borderRadius: '10px',
                    border: warning ? '1px solid red' : 'none'}}>
                    <div className={'labelling-content'}>
                        What is the approx age of parrot {num}?
                        <QuestionCircleOutlined className={'sex-age-examples-icon'} style={{ marginLeft:"6px", fontSize: '17px'}} onClick={showAgeExamples}/>
                    </div>
                    <div style={{
                        display: 'flex',
                        gap: '8px',
                        margin: '0 20px'}}>
                        <Button style={{ 
                                backgroundColor: (gspActiveButton === 'button_in_y' & bandedNum !== 0) ? (buttonStates[index].age === ('button_age_fle'+num) ? '#a5c7ff' : '#e0e0e0') : null, 
                                color: (gspActiveButton === 'button_in_y' & bandedNum !== 0) ? 'black' : null, 
                                width: 55,
                                alignItems: 'center'
                            }}
                            onClick={() => handleClick('button_age_fle'+num, 'fle', index)} 
                            disabled={(gspActiveButton === 'button_in_y' & bandedNum !== 0) ? false : true} >
                            fle
                        </Button>
                        <Button style={{ 
                                backgroundColor: (gspActiveButton === 'button_in_y' & bandedNum !== 0) ? (buttonStates[index].age === ('button_age_juv'+num) ? '#a5c7ff' : '#e0e0e0') : null, 
                                color: (gspActiveButton === 'button_in_y' & bandedNum !== 0) ? 'black' : null, 
                                width: 55
                            }}
                            onClick={() => handleClick('button_age_juv'+num, 'juv', index)} 
                            disabled={(gspActiveButton === 'button_in_y' & bandedNum !== 0) ? false : true} >
                            juv
                        </Button>
                        <Button style={{ 
                                backgroundColor: (gspActiveButton === 'button_in_y' & bandedNum !== 0) ? (buttonStates[index].age === ('button_age_adu'+num) ? '#a5c7ff' : '#e0e0e0') : null, 
                                color: (gspActiveButton === 'button_in_y' & bandedNum !== 0) ? 'black' : null, 
                                width: 55
                            }}
                            onClick={() => handleClick('button_age_adu'+num, 'adu', index)} 
                            disabled={(gspActiveButton === 'button_in_y' & bandedNum !== 0) ? false : true} >
                            adu
                        </Button>
                        <Button style={{ 
                                backgroundColor: (gspActiveButton === 'button_in_y' & bandedNum !== 0) ? (buttonStates[index].age === ('button_age_unknown'+num) ? '#a5c7ff' : '#e0e0e0') : null, 
                                color: (gspActiveButton === 'button_in_y' & bandedNum !== 0) ? 'black' : null, 
                                width: 90,
                                alignItems: 'center'
                            }}
                            onClick={() => handleClick('button_age_unknown'+num, 'unknown', index)} 
                            disabled={(gspActiveButton === 'button_in_y' & bandedNum !== 0) ? false : true} >
                            unknown
                        </Button>
                        <Modal 
                            title='Age Examples'
                            open={isShowAgeExamples} 
                            onOk={closeShowAgeExamples}
                            onCancel={closeShowAgeExamples}
                            footer={[
                                <Button key="showAgeExamples" type="primary" onClick={closeShowAgeExamples}>
                                    OK
                                </Button>,
                                ]}>
                                <Button key="showAgeExamples_m" 
                                    type={ageExamplesSex === 'm' ? 'primary' : 'default'}
                                    onClick={setExamplesAge_m}
                                    style={{ width: '50px', borderRadius: '0' }}>
                                    M
                                </Button>
                                <Button key="showAgeExamples_f"
                                    type={ageExamplesSex === 'f' ? 'primary' : 'default'}
                                    onClick={setExamplesAge_f}
                                    style={{ width: '50px', borderRadius: '0' }}>
                                    F
                                </Button>
                                {ageExamplesPage()}
                        </Modal>
                    </div>
                </div>
            </>
        )}
    }

    const sexContent = (num, handleClick, index) => {
        const sexId = `GSP-sex${num}`
        let warning;
        if (warningList.includes(sexId)) {
            warning = true;
        } else {
            warning = false;
        }
        if (buttonStates.length === bandedNum) {
            return (
                <>
                    <Divider style={{marginTop: '10px', marginBottom: '10px'}}/>
                    <div id={sexId} style={{
                        paddingTop: '10px',
                        paddingBottom: '10px',
                        borderRadius: '10px',
                        border: warning ? '1px solid red' : 'none'}}>
                        <div className={'labelling-content'}>
                            Of BANDED GSP, what is the sex of parrot {num}?
                            <QuestionCircleOutlined className={'sex-age-examples-icon'} style={{ marginLeft:"6px", fontSize: '17px'}} onClick={showAgeExamples}/>
                        </div>
                        <div style={{
                            display: 'flex',
                            gap: '20px',
                            margin: '0 20px'}}>
                            <Button style={{ 
                                    backgroundColor: (gspActiveButton === 'button_in_y' & bandedNum !== 0) ? (buttonStates[index].sex === ('button_sex_m'+num) ? '#a5c7ff' : '#e0e0e0') : null, 
                                    color: (gspActiveButton === 'button_in_y' & bandedNum !== 0) ? 'black' : null, 
                                    width: 60
                                }}
                                onClick={() => handleClick('button_sex_m'+num, 'm', index)} 
                                disabled={(gspActiveButton === 'button_in_y' & bandedNum !== 0) ? false : true} >
                                M
                            </Button>
                            <Button style={{ 
                                    backgroundColor: (gspActiveButton === 'button_in_y' & bandedNum !== 0) ? (buttonStates[index].sex === ('button_sex_f'+num) ? '#a5c7ff' : '#e0e0e0') : null, 
                                    color: (gspActiveButton === 'button_in_y' & bandedNum !== 0) ? 'black' : null, 
                                    width: 60
                                }}
                                onClick={() => handleClick('button_sex_f'+num, 'f', index)} 
                                disabled={((gspActiveButton === 'button_in_y' & bandedNum !== 0) & bandedNum !== 0) ? false : true} >
                                F
                            </Button>
                            <Button style={{ 
                                    backgroundColor: (gspActiveButton === 'button_in_y' & bandedNum !== 0) ? (buttonStates[index].sex === ('button_sex_unknown'+num) ? '#a5c7ff' : '#e0e0e0') : null, 
                                    color: (gspActiveButton === 'button_in_y' & bandedNum !== 0) ? 'black' : null, 
                                    width: 90
                                }}
                                onClick={() => handleClick('button_sex_unknown'+num, 'unknown', index)} 
                                disabled={(gspActiveButton === 'button_in_y' & bandedNum !== 0) ? false : true} >
                                unknown
                            </Button>
                        </div>
                    </div>
                </>
            )
        }
    }

    function toCamelCase(str) {
        return str
          .split(' ')
          .map((word, index) => {
            return index === 0 ? word.toLowerCase() : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
          })
          .join('');
    }

    const chooseColor = (handleChange, colorOptions, name, index) => {
        if (buttonStates.length === bandedNum) {
            let value;
            if (buttonStates.length === 0 || buttonStates[index][`${toCamelCase(name)}`] === null) {
                value = leftLowerColorOptions[0].value[0]+leftLowerColorOptions[0].value[1];
            } else {
                value = buttonStates[index][`${toCamelCase(name)}`];
            }
            return (
                <>
                    <div className={'labelling-color'} style={{ 
                        display: 'flex', alignItems: 'center', marginTop: '5px', marginBottom: '5px', marginLeft: '20px', marginRight: '20px' }}>
                        <div style={{ marginRight: '16px', alignSelf: 'center', width: '100px'}}>{name}</div>
                        <Select
                                defaultValue={leftLowerColorOptions[0].value[0]+leftLowerColorOptions[0].value[1]} // 设置默认值
                                value={value}
                                style={{ width: 150 }}
                                onChange={(e) => handleChange(e, index)}
                                labelInValue
                                disabled={gspActiveButton !== 'button_in_y' | bandedNum === 0 ? true : false}
                                >
                            {colorOptions.map((color) => (
                            <Option key={color.label} value={color.value[0]+color.value[1]}>
                                <div style={{
                                    marginRight: '8px',
                                    lineHeight: '20px',
                                    display: 'inline-block',
                                    verticalAlign: 'middle',
                                    marginBottom: '3px',
                                    border: color.label !== 'None' 
                                            && color.label !== 'Unsure' 
                                            && color.label !== 'Not Visible' ? '1px solid black' : 'none',
                                }}>
                                    <div
                                    style={{
                                        backgroundColor: color.value[0],
                                        width: '20px',
                                        height: '10px',
                                    }}/>
                                    <div
                                    style={{
                                        backgroundColor: color.value[1],
                                        width: '20px',
                                        height: '10px',
                                    }}/>
                                </div>
                                {color.label}
                            </Option>   
                            ))}
                        </Select>
                    </div>
                </>
            )
        }
    }

    const colorContent = (num, handleRightUpperChange, handleRightLowerChange, handleLeftUpperChange, 
                        handleLeftLowerChange, index) => {
        if (buttonStates.length === bandedNum) {
            return (
            <>
                <Divider style={{marginTop: '10px', marginBottom: '10px'}}/>
                <div style={{
                    paddingTop: '10px',
                    paddingBottom: '10px'}}>
                    <div className={'labelling-content'} style={{display: 'flex', justifyContent: 'space-between'}}>
                        Parrot {num} Band Colour:
                        <QuestionCircleOutlined className={'sex-age-examples-icon'} style={{ marginLeft:"6px", fontSize: '17px'}} onClick={showBandedExamples}/>
                    </div>
                    {chooseColor(handleRightUpperChange, rightUpperColorOptions, "Right Upper", index)}
                    {chooseColor(handleRightLowerChange, rightLowerColorOptions, "Right Lower", index)}
                    {chooseColor(handleLeftUpperChange, leftUpperColorOptions, "Left Upper", index)}
                    {chooseColor(handleLeftLowerChange, leftLowerColorOptions, "Left Lower", index)}
                </div>
                <Modal 
                    title='Band Examples'
                    open={isShowBandedExamples}
                    onOk={closeShowBandedExamples}
                    onCancel={closeShowBandedExamples}
                    width={900}
                    footer={[
                        <Button key="showAgeExamples" type="primary" onClick={closeShowBandedExamples}>
                            OK
                        </Button>,
                        ]}>
                        <Flex >
                            <Image className='age_examples_img' src='/img/band_examples/left_right.jpg' />
                            <Image className='age_examples_img' src='/img/band_examples/banding_code.jpg' />
                        </Flex>
                </Modal>
                <Divider style={{marginTop: '10px', marginBottom: '10px'}}/>
            </>
        )}
    }

    const renderAdditionalContent = () => {
        return Array.from({ length: bandedNum-1 }, (_, index) => (
            <React.Fragment key={index}>
              {sexContent((index+2).toString(), gspSexHandleClick, index+1)}
              {approximateAgeContent((index+2).toString(), gspAgeHandleClick, index+1)}
              {colorContent((index+2).toString(), handleRightUpperChange, handleRightLowerChange, 
                  handleLeftUpperChange, handleLeftLowerChange, index+1)}
            </React.Fragment>
        ));
    };

    return (
        <ConfigProvider locale={en_GB}>
            { globalInfo.user.userRole && (
            <GlobalInfoProvider>
                <>  
                    <Layout style={{ height: '100%' }}>
                        <div className={"header"}>
                            <div className={"logo"} onClick={() => goHomePage()}>WILD SEARCH</div>
                        </div>
                        <Header className='bg-white px-4'>
                            <div className='flex'>
                                <div className='flex-1' >
                                    <div className='float-right mr-6'>
                                        <UserAvatar />
                                    </div>
                                </div>
                            </div>
                        </Header>
                        <Layout >
                            <Sider width={320} style={{ background: 'white', height: '100%'}} collapsed={collapsed} className='min-h-80'>
                                <div className={"sider-left"}>
                                    <div
                                        id="scrollableDiv"
                                        ref={(ref) => setScrollableTarget(ref)}
                                        style={{
                                            height: "100%",
                                            overflow: 'auto',
                                            padding: '0 20px',
                                            borderRadius: '10px',
                                            backgroundColor: 'white',
                                        }}
                                        >
                                        <InfiniteScroll
                                            dataLength={data.length}
                                            next={loadMoreData}
                                            hasMore={data.length < imageNum}
                                            loader={<Skeleton.Image paragraph={{ rows: 1 }} active />}
                                            endMessage={<Divider plain>It is all, nothing more 🤐</Divider>}
                                            scrollableTarget="scrollableDiv"
                                        >
                                        <List
                                        dataSource={data}
                                        renderItem={(item, index) => (
                                            <List.Item key={item.photoId} onClick={() => {
                                                handlePhotoClick(item);
                                                setSelectedPhotoIndex(index);
                                                }}>
                                                <div id={'list-item'} style={{width: '264px', position: 'relative'}}>
                                                    <div className={`image-container ${selectedPhotoIndex === index ? 'selected' : ''}`}>
                                                        <img width={256} alt="images" src={BASE_URL+item.fileUrl}/>
                                                        {item.markStatus === 'L' && (
                                                            <div className="labelled-marker-marked">
                                                                <CheckOutlined style={{ fontSize: '13px', fontWeight: 'bold', color: 'white' }} />
                                                            </div>
                                                        )}
                                                        {item.markStatus === 'F' && (
                                                            <div className="labelled-marker-mark-failed">
                                                                <ExclamationCircleOutlined style={{ fontSize: '13px', fontWeight: 'bold', color: 'white' }} />
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div style={{ wordWrap: 'break-word' }}>{item.fileName}</div>
                                                </div>
                                            </List.Item>
                                        )}/>
                                        </InfiniteScroll>
                                    </div>
                                </div>
                            </Sider>
                            <Layout >
                                <Content style={{ background: '#e0e0e0', paddingTop: '8px', paddingBlock: '8px', paddingLeft: '4px', paddingRight: '4px'}} >
                                    <div className={"imageContent"}>
                                        {selectedPhoto && (
                                            <>
                                                <Image className='w-16' style={{ height: '100%' }} alt="selected-image" src={BASE_URL + selectedPhoto.fileUrl} />
                                            </>
                                        )}
                                        <div style={{ textAlign: 'center', marginTop: '10px' }}>
                                            <Button size="large" type="primary" shape="circle" icon={<ArrowLeftOutlined />} onClick={() => handleArrowClick('left')}/>
                                            {selectedPhoto && (
                                            <>
                                                <span style={{ marginRight: '18px' }}></span>
                                                {selectedPhoto.fileName}
                                                <span style={{ marginLeft: '18px' }}></span>
                                            </>
                                            )}
                                            <Button size="large" type="primary" shape="circle" icon={<ArrowRightOutlined />} onClick={() => handleArrowClick('right')}/>
                                        </div>
                                    </div>
                                </Content>
                            </Layout>
                            <Sider width={320} style={{ background: 'white', height: '100%'}} collapsed={collapsed} className='min-h-80' >
                                <div className={'sider-right'}>
                                    <div style={{height: '100%', background: 'white', borderRadius: '10px'}}>
                                        <div id="label-sider" style={{
                                                height: "95%",
                                                overflow: 'auto',
                                                borderRadius: '10px',
                                                backgroundColor: 'white',
                                            }}>
                                            <div className={'labelling-warning'}
                                                style={{
                                                    display: isShowWarning ? 'flex': 'none',
                                                }}>
                                                <span style={{
                                                    marginTop: '14px',
                                                    display: 'flex',
                                                    alignItems: 'center'}}>
                                                    <ExclamationCircleOutlined 
                                                            style={{
                                                                color: 'red',
                                                                fontSize: '28px',
                                                                marginRight: '8px'}}/>
                                                    Please answer the required questions
                                                </span>
                                                <span onClick={jumpToWarning} style={{ marginTop: '8px', marginBottom: '10px',color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}>
                                                    Show Detail
                                                </span>
                                            </div>
                                            <div id="GSP-active" style={{
                                                    paddingTop: '10px',
                                                    paddingBottom: '10px',
                                                    borderRadius: '10px',
                                                    border: isGspActiveButtonWarning ? '1px solid red' : 'none'}}>
                                                <div className={'labelling-content'}>
                                                    Is there any GSP visible in this image?
                                                </div>
                                                <div style={{
                                                    display: 'flex',
                                                    gap: '20px',
                                                    margin: '0 20px'}}>
                                                    <Button style={{ backgroundColor: gspActiveButton === 'button_in_y' ? '#a5c7ff' : '#e0e0e0',
                                                        color: 'black', width: 60}}
                                                        onClick={() => gspHandleClick('button_in_y', 'Y')} >
                                                        Yes
                                                    </Button>
                                                    <Button style={{ backgroundColor: gspActiveButton === 'button_in_n' ? '#a5c7ff' : '#e0e0e0',
                                                        color: 'black', width: 60}}
                                                        onClick={() => gspHandleClick('button_in_n', 'N')} >
                                                        No
                                                    </Button>
                                                </div>
                                            </div>
                                            <Divider style={{marginTop: '10px', marginBottom: '10px'}}/>
                                            <div id="GSP-visible" style={{
                                                    paddingTop: '10px',
                                                    paddingBottom: '10px',
                                                    borderRadius: '10px',
                                                    border: isGspVisibleButtonWarning ? '1px solid red' : 'none'}}>
                                                <div className={'labelling-content'}>
                                                    How many GSP are visible?
                                                </div>
                                                <div style={{
                                                    display: 'flex',
                                                    gap: '20px',
                                                    margin: '0 20px'}}>
                                                    <Button style={{ 
                                                            backgroundColor: gspActiveButton === 'button_in_y' ? (gspVisibleButton === 'button_num_1' ? '#a5c7ff' : '#e0e0e0') : null, 
                                                            color: gspActiveButton === 'button_in_y' ? 'black' : null, 
                                                            width: 60
                                                        }}
                                                        onClick={() => gspVisibleClick('button_num_1', 1)} 
                                                        disabled={gspActiveButton === 'button_in_y' ? false : true} >
                                                        1
                                                    </Button>
                                                    <Button style={{ 
                                                            backgroundColor: gspActiveButton === 'button_in_y' ? (gspVisibleButton === 'button_num_2' ? '#a5c7ff' : '#e0e0e0') : null, 
                                                            color: gspActiveButton === 'button_in_y' ? 'black' : null, 
                                                            width: 60
                                                        }}
                                                        onClick={() => gspVisibleClick('button_num_2', 2)} 
                                                        disabled={gspActiveButton === 'button_in_y' ? false : true} >
                                                        2
                                                    </Button>
                                                    <Button style={{ 
                                                            backgroundColor: gspActiveButton === 'button_in_y' ? (gspVisibleButton === 'button_num_3' ? '#a5c7ff' : '#e0e0e0') : null, 
                                                            color: gspActiveButton === 'button_in_y' ? 'black' : null, 
                                                            width: 60
                                                        }}
                                                        onClick={() => gspVisibleClick('button_num_3', 3)} 
                                                        disabled={gspActiveButton === 'button_in_y' ? false : true} >
                                                        3
                                                    </Button>
                                                    <InputNumber 
                                                        min={1} 
                                                        max={20} 
                                                        controls={true}
                                                        step={1} 
                                                        formatter={value => `${value}`.replace(/\D/g, '')}
                                                        parser={value => parseInt(value || '0', 10)}
                                                        style={{ 
                                                            width: 80
                                                        }}
                                                        value={visibleNum}
                                                        onChange={clearVisibleButton}
                                                        disabled={gspActiveButton === 'button_in_y' ? false : true} >
                                                        
                                                    </InputNumber>
                                                </div>
                                            </div>
                                            <Divider style={{marginTop: '10px', marginBottom: '10px'}}/>
                                            <div id="GSP-number" style={{
                                                    paddingTop: '10px',
                                                    paddingBottom: '10px',
                                                    borderRadius: '10px',
                                                    border: isGspNumActiveButtonWarning ? '1px solid red' : 'none'}}>
                                                <div className={'labelling-content'}>
                                                    How many BANDED GSP are visible?
                                                </div>
                                                <div style={{
                                                    display: 'flex',
                                                    gap: '20px',
                                                    margin: '0 20px'}}>
                                                    <Button 
                                                        style={{ 
                                                            backgroundColor: gspActiveButton === 'button_in_y' & 0 <= visibleNum ? (gspNumActiveButton === 'button_num_0' ? '#a5c7ff' : '#e0e0e0') : null, 
                                                            color: gspActiveButton === 'button_in_y' & 0 <= visibleNum ? 'black' : null, 
                                                            width: 60
                                                        }}
                                                        onClick={() => gspNumHandleClick('button_num_0', 0)} 
                                                        disabled={gspActiveButton === 'button_in_y' & 0 <= visibleNum ? false : true} >
                                                        0
                                                    </Button>
                                                    <Button 
                                                        style={{ 
                                                            backgroundColor: gspActiveButton === 'button_in_y' & 1 <= visibleNum ? (gspNumActiveButton === 'button_num_1' ? '#a5c7ff' : '#e0e0e0') : null, 
                                                            color: gspActiveButton === 'button_in_y' & 1 <= visibleNum ? 'black' : null, 
                                                            width: 60
                                                        }}
                                                        onClick={() => gspNumHandleClick('button_num_1', 1)} 
                                                        disabled={gspActiveButton === 'button_in_y' & 1 <= visibleNum ? false : true} >
                                                        1
                                                    </Button>
                                                    <Button style={{ 
                                                            backgroundColor: gspActiveButton === 'button_in_y' & 2 <= visibleNum  ? (gspNumActiveButton === 'button_num_2' ? '#a5c7ff' : '#e0e0e0') : null, 
                                                            color: gspActiveButton === 'button_in_y' & 2 <= visibleNum ? 'black' : null, 
                                                            width: 60
                                                        }}
                                                        onClick={() => gspNumHandleClick('button_num_2', 2)} 
                                                        disabled={gspActiveButton === 'button_in_y' & 2 <= visibleNum ? false : true} >
                                                        2
                                                    </Button>
                                                    <InputNumber 
                                                        min={0} 
                                                        max={20} 
                                                        controls={true}
                                                        step={1} 
                                                        formatter={value => `${value}`.replace(/\D/g, '')}
                                                        parser={value => parseInt(value || '0', 10)}
                                                        style={{ 
                                                            width: 80
                                                        }}
                                                        value={bandedNum}
                                                        onChange={clearBandedButton}
                                                        disabled={gspActiveButton === 'button_in_y' & 3 <= visibleNum ? false : true} >
                                                        
                                                    </InputNumber>
                                                </div>
                                            </div>
                                            {sexContent('1', gspSexHandleClick, 0)}
                                            {approximateAgeContent('1', gspAgeHandleClick, 0)}
                                            {colorContent('1', handleRightUpperChange, handleRightLowerChange, 
                                                handleLeftUpperChange, handleLeftLowerChange, 0)}

                                            {renderAdditionalContent()}
                                            
                                            <div style={{
                                                paddingTop: '10px'}}>
                                                <div className={'labelling-content'}>
                                                    Any additional comments?
                                                </div>
                                                <div style={{margin: '0 20px'}}>
                                                    <TextArea
                                                        value={comments}
                                                        onChange={(e) => setComments(e.target.value)}
                                                        placeholder="Please enter..."
                                                        autoSize={{ minRows: 3, maxRows: 5 }}
                                                        style={{marginBottom: '20px'}}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{
                                            height: '5%',
                                            display: 'flex',
                                            alignItems: "center",
                                            justifyContent: 'space-between',
                                            bottom: '76px',  
                                            backgroundColor: 'white',
                                            width: '100%',
                                            borderRadius: '10px',
                                            }}>
                                            {/* <Button style={{ backgroundColor: '#85c1ff',
                                                color: 'black', width: 150}}
                                                onClick={() => draftHandleClick()} >
                                                SAVE AS DRAFT
                                            </Button> */}
                                            <Button style={{ backgroundColor: '#543aff',
                                                color: 'white', width: "270px", marginBottom: '10px', marginLeft: '20px' }}
                                                loading={saving}
                                                onClick={() => {
                                                    saveHandleClick();
                                                    scrollToTop("label-sider");
                                                }} >
                                                SUBMIT
                                            </Button>
                                            <Modal 
                                                title={submitTitle}
                                                open={isModalOpen} 
                                                onOk={handleOk}
                                                footer={[
                                                    <Button key="ok" type="primary" loading={loading} onClick={handleOk}>
                                                    OK
                                                    </Button>,
                                                ]}
                                            >
                                                <p>{submit}</p>
                                            </Modal>
                                            <Modal 
                                                centered
                                                title='Warning'
                                                open={showBandedWarning}
                                                onCancel={closeBandedWaring_no}
                                                footer={[
                                                    <Button key="showBandedWarning_yes" type="primary" onClick={closeBandedWaring_yes} style={{width: '60px'}}>
                                                        YES
                                                    </Button>,
                                                    <Button key="showBandedWarning_no" onClick={closeBandedWaring_no} style={{width: '60px'}}>
                                                        NO
                                                    </Button>,
                                                    ]}>
                                                    <div>
                                                        The system doesn't have the specified leg band color combination of <b>{bandedWarning}</b>. Do you want to proceed with the submission?
                                                    </div>
                                            </Modal>
                                        </div>
                                    </div>
                                </div>
                            </Sider>
                        </Layout>
                        <Footer className='text-center'>
                            ParrotsLabelling ©2024 Created by WildSearch
                        </Footer>
                    </Layout>
                </>
            </GlobalInfoProvider>
            )}
        </ConfigProvider>
    );
};