import React, { useState, useRef, useEffect } from 'react';
import { Button, Input, Space, Checkbox } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import "./style.css"


const useColumnSearchProps = (dataIndex, options) => {
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const [isFocused, setIsFocused] = useState(false);
    const [filteredOptions, setFilteredOptions] = useState(options);
    const [filtered, setFiltered] = useState(false);

    useEffect(() => {
        if (filteredOptions.length === 0) {
            setFilteredOptions(options);
        }
    }, [options]);

    const handleSearch = (
        selectedKeys,
        confirm,
        dataIndex,
    ) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
        if (selectedKeys[0]) {
            setFiltered(true);
        }
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const handleInputChange = (e, setSelectedKeys) => {
        const inputValue = e.target.value.toLowerCase();
        const filtered = options.filter(option => option.value.toLowerCase().includes(inputValue));
        setFilteredOptions(filtered);
        setSelectedKeys(e.target.value ? [e.target.value] : [])
    };

    const getColumnSearchProps = () => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div className={'search'} onKeyDown={(e) => e.stopPropagation()}>
                <Input
                    ref={searchInput}
                    placeholder="Search in filters"
                    prefix={<SearchOutlined style={{ color: "#BFBFBF" }} />}
                    value={selectedKeys[0]}
                    onChange={(e) => handleInputChange(e, setSelectedKeys)}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ display: 'flex', borderColor: isFocused ? '#81B338' : '#BFBFBF' }}
                    className={"search-input"}
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                />
                <Checkbox.Group
                    className={"search"}
                    value={selectedKeys}
                    onChange={(checkedValues) => setSelectedKeys(checkedValues)}
                >
                    {filteredOptions.map(option => (
                        <Checkbox
                            style={{ marginTop: '2px', marginBottom: '2px', fontWeight: "normal" }}
                            key={option.value}
                            value={option.value}
                        >
                            {option.value}
                        </Checkbox>
                    ))}
                </Checkbox.Group>
                <Space>
                    <Button
                        disabled={selectedKeys[0] ? false : true}
                        className={selectedKeys[0] ? 'reset-button' : null }
                        onClick={() => {
                            clearFilters && handleReset(clearFilters);
                            setFilteredOptions(options);
                            selectedKeys[0] = "";
                            setSearchText(null);
                            setSearchedColumn(dataIndex);
                            setFiltered(false);
                            }
                        }
                        size="small"
                        style={{ width: 90 }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="primary"
                        className={'save-details-change-button'}
                        onClick={() => {
                            handleSearch(selectedKeys, confirm, dataIndex);
                            close();
                        }}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: () => (
            <SearchOutlined style={{ color: filtered ? 'green' : 'gray' }} />
        ),
        onFilter: (value, record) => {
            if (value) {
                return record[dataIndex].toString().toLowerCase() === value.toLowerCase()
            }
            return true
        },
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    return getColumnSearchProps();
};

export default useColumnSearchProps;
