import React from "react";

function Footer() {
    return (
      <>
        <div className={'footer'}>
            <div className={'footer-title'}>
                Partnerships & Collaborations
            </div>
            <div className={'footer-img-container'}>
                <a href="https://www.uq.edu.au" target="_blank" rel="noreferrer">
                    <img className={"footer-img"} src={require("./icon/UQ.png") } alt="" />
                </a>
                <a href="https://conservationpartners.org.au" target="_blank" rel="noreferrer">
                    <img className={"footer-img"} src={require("./icon/conservation.jpg")} alt=""/>
                </a>
                <a href="https://www.csiro.au" target="_blank" rel="noreferrer">
                    <img className={"footer-img"} src={require("./icon/CSIRO.png")} alt=""/>
                </a>
                <a href="https://www.jcu.edu.au" target="_blank" rel="noreferrer">
                    <img className={"footer-img"} src={require("./icon/JCU.png")} alt=""/>
                </a>
                <a href="https://www.nsw.gov.au" target="_blank" rel="noreferrer">
                    <img className={"footer-img"} src={require("./icon/NSW.jpg")} alt=""/>
                </a>
            </div>
            <div className={'footer-contact-info'}>
                Contact info
            </div>
            <div className={'footer-email'}>
                <a href="mailto:uqwildsearching@gmail.com" target="_blank" rel="noreferrer">uqwildsearching@gmail.com</a>
            </div>
            <div className={'footer-ventures'}>
                <a href="https://ventures.uq.edu.au/article/2023/07/bird-identification-ai-technology-rises-top-pecking-order" target="_blank" rel="noreferrer">UQ Ventures</a>
            </div>
        </div>
      </>
    )
}

export default Footer