import { createContext, useEffect, useState } from "react";
import { userApi } from "./api/user/user-api";
import { jumpToLoginPage } from "./lib/browser-utils";


export const GlobalInfoContext = createContext({});

export const GlobalInfoProvider = ({ children }) => {
    const [userData, setUserData] = useState({ user: { firstName: "" } });
    useEffect(() => {
        userApi.checkToken()
            .then(userData => {
                if (!userData || Object.keys(userData).length === 0) {
                    // userData is empty, set firstName to "login"
                    setUserData({
                        user: {
                            firstName: ""
                        },
                    });
                    if (!(window.location.pathname === "/login" || window.location.pathname === "/")) {
                        jumpToLoginPage();
                    }
                } else {
                    // userData is not empty, set userData to user
                    setUserData({
                        user : userData,
                    });
                }
            })
            .catch(error => {
                console.error("[PowerAdmin]GlobalInforProvider error:", error)
            });
    }, [])

    return <GlobalInfoContext.Provider value={userData}>{children}</GlobalInfoContext.Provider>;
}
