import { postRequestWithParams, getRequest, postRequest } from "../../lib/request";
import { clearAllCookies, saveTokenToCookie, saveUidToCookie } from "../../lib/cookie";

export const loginApi = {
    login: async (data) => {
        // 调用后端的登录接口，获取登录信息
        const loginInfo = await postRequestWithParams("/public/user/login", data);
        // 先清空本地保存的cookies
        clearAllCookies();
        // 在将返回的JWT Token和uid保存到本地Cookie，以便其它接口的调用
        if (loginInfo) {
            saveTokenToCookie(loginInfo.token);
            saveUidToCookie(loginInfo.userId);
            return true;
        }
        return false;
    },

    logout: () => {
        // 简单起见，我们完全依赖JWT Token的过期机制，
        // 因此，注销只需清空本地Cookies。
        clearAllCookies(); 
    },

    checkCanRegister: async (data) => {
        return await getRequest("/public/user/login/accountValidate", data);
    },

    createAccount: async (data) => {
        const registerInfo = await postRequest("/public/user/register", data);
        if (registerInfo) {
            clearAllCookies(); 
            saveTokenToCookie(registerInfo.token);
            saveUidToCookie(registerInfo.userId);
        }
        return registerInfo;
    },

    checkCaptcha: async (data) => {
        return await getRequest("/public/user/captcha", data);
    },
};