import React from "react";

function StepPage() {
    return (
        <>
            <div className={'step-page'}>
                <div className={'step-page-container'}>
                    <img className={"upload-img"} src={require("./img/upload.png")} alt=""/>
                    <span>1</span>
                    <div className={'step-page-description-container'}>
                        <div className={'step-page-title'}>
                            Upload
                        </div>
                        <div className={'step-page-content'}>
                            Upload your raw dataset<br/>
                            photos, videos, or audio to the cloud
                        </div>
                    </div>
                    <div className={'step-page-slogan1'}>
                        Efficiently data transfer, <br/>
                        get precise labeling, <br/>
                        and swift insights.
                    </div>
                </div>
                <div className={'step-page-container'}>
                    <img className={"train-img"} src={require("./img/train.png")} alt=""/>
                    <span>2</span>
                    <div className={'step-page-description-container'}>
                        <div className={'step-page-title'}>
                            Train
                        </div>
                        <div className={'step-page-content'}>
                            We will manually label the raw dataset<br/>
                            based on your specifications and conduct AI model training
                        </div>
                    </div>
                    <div className={'step-page-slogan2'}>
                        Save time for your workflow
                    </div>
                </div>
                <div className={'step-page-container'}>
                    <img className={"analysis-img"} src={require("./img/analysis.png")} alt=""/>
                    <span>3</span>
                    <div className={'step-page-description-container'}>
                        <div className={'step-page-title'}>
                            Analysis
                        </div>
                        <div className={'step-page-content'}>
                            The data results will be presented according to your individual preferences, using formats<br/>
                            like Excel, charts, or any other method of your choice.
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default StepPage