import Cookies from "js-cookie";

export const COOKIE_TOKEN_KEY = 'X-Access-Token';
export const COOKIE_Uid_KEY = 'X-Uid';

export const clearAllCookies = () => {
    Cookies.remove(COOKIE_TOKEN_KEY);
    Cookies.remove(COOKIE_Uid_KEY);
};

export const getTokenFromCookie = () => {
    return Cookies.get(COOKIE_TOKEN_KEY);
};

export const saveTokenToCookie = (token) => {
    Cookies.set(COOKIE_TOKEN_KEY, token, { expires: 365 });
};

export const saveUidToCookie = (uid) => {
    Cookies.set(COOKIE_Uid_KEY, uid, { expires: 365 });
};

export const getUidFromCookie = () => {
    return Cookies.get(COOKIE_Uid_KEY);
};
