import { checkTokenValid, putRequest, putRequestWithParams } from "../../lib/request"

export const userApi = {
    checkToken: async () => {
        return await checkTokenValid(`/public/user/checkToken`);
    },

    updateAccountDetails: async (data) => {
        try {
            return await putRequest(`/user/updateUserInfo`, data);
        } catch {
            return false;
        }
        
    },

    changePassword: async (data) => {
        try {
            return await putRequestWithParams(`/user/changePassword`, data);
        } catch {
            return false;
        }
        
    },
};
