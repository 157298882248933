export const jumpToPage = (path) => {
    setTimeout(() => {
        window.location.href = path
    }, 300);
}

export const jumpToLoginPage = () => {
    return jumpToPage("/login");
}

export const jumpToHomePage = () => {
    return jumpToPage("/");
}

export const jumpToLabellingPage = () => {
    return jumpToPage("/labelling");
}

export const jumpToAccountDetailsPage = () => {
    return jumpToPage("/account");
}
